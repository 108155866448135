$(document).ready(function(){

    //
    // TAB/TOGGLE UPDATE LOGIC
    //

    let desktopTabs = '#desktop-tabs .nav-link';
    let dropdownTabs = '#mobile-tab-toggle .dropdown-item';

    // Ensure that mobile dropdown menu 'active' link states reflected when desktop nav is clicked.
    $(desktopTabs).on('shown.bs.tab', function() {
        let target = $(this).attr('href');

        $(dropdownTabs).removeClass('show').removeClass('active').attr("aria-selected", "false");
        $(dropdownTabs + "[href='" + target + "']").addClass('active').attr("aria-selected", "true");
    });

    // Ensure that desktop nav 'active' link states reflected when mobile dropdown links are clicked.
    $(dropdownTabs).on('shown.bs.tab', function() {
        let target = $(this).attr('href');

        $(dropdownTabs).attr("aria-selected", "false");
        $(this).attr("aria-selected", "true");

        $(desktopTabs).removeClass('show').removeClass('active');
        $(desktopTabs + "[href='" + target + "']").addClass('active');
    });
});